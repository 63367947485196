var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',[_c('StandardCardHeader',{attrs:{"go-to-link":'admin-todo-list',"title":'Create todo',"button-text":'Close',"show-action-button":true,"link-button-color":'error'},on:{"action":function($event){return _vm.save()}}}),_c('v-form',{ref:"form",staticClass:"ma-0 pt-6",attrs:{"lazy-validation":""}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","label":_vm.$t('First name')},model:{value:(_vm.todo.title),callback:function ($$v) {_vm.$set(_vm.todo, "title", $$v)},expression:"todo.title"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-select',{staticClass:"w-full",attrs:{"dense":"","outlined":"","items":_vm.$store.getters.getUsers,"item-text":"name","item-value":"id","label":_vm.$t('User')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.todo.user_id),callback:function ($$v) {_vm.$set(_vm.todo, "user_id", $$v)},expression:"todo.user_id"}})],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"w-full mb-1",attrs:{"dense":"","outlined":"","label":_vm.$t('Deadline'),"hide-details":""},model:{value:(_vm.todo.deadline),callback:function ($$v) {_vm.$set(_vm.todo, "deadline", $$v)},expression:"todo.deadline"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{attrs:{"locale":"srLatn"},on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.todo.deadline),callback:function ($$v) {_vm.$set(_vm.todo, "deadline", $$v)},expression:"todo.deadline"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4","lg":"4"}},[_c('v-select',{staticClass:"w-full",attrs:{"rules":[_vm.validator.required],"dense":"","outlined":"","items":_vm.$store.state.todoTypes,"item-text":function (item) { return _vm.$t(item.name); },"item-value":"key","label":_vm.$t('Type')},scopedSlots:_vm._u([{key:"message",fn:function(ref){
var message = ref.message;
return [_vm._v(" "+_vm._s(_vm.$t(message))+" ")]}}]),model:{value:(_vm.todo.type),callback:function ($$v) {_vm.$set(_vm.todo, "type", $$v)},expression:"todo.type"}})],1)],1)],1)],1),_c('StandardCardHeader',{attrs:{"go-to-link":'admin-todo-list',"button-text":'Close',"show-action-button":true,"link-button-color":'error'},on:{"action":function($event){return _vm.save()}}})],1),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar = false}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('Close'))+" ")])]}}]),model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.$t('Data successfully saved'))+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }